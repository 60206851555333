import React from 'react'

import Layout from '../components/Layout'
import H1 from '../components/H1'

const Honey = () => (
  <Layout>
    <div>
      <H1>Miód pszczeli</H1>
      Jest to naturalny produkt wytwarzany z nektaru kwiatowego lub spadzi. Nektar z kwiatów bądź spadż zbierana jest przez pszczoły, a następnie przerabiane w ulu przez pszczoły ulowe. Pszczoły ulowe to nieskażone młode robotnice, które jeszcze nie wylatują z ula przerabiając nektar dodają do niego wydzielinę swoich gruczołów nazywaną enzymami. Dzięki pracy pszczół dojrzewa i jest składany do komórek plastra pszczelego. Każdy dobry <em>miód pszczeli</em> wcześniej czy później krystalizuje, jest to jego naturalny proces. Krystalizacja uzależniona jest od proporcji cukrów prostych (glukozy, fruktozy). Miody, które posiadają większą zawartość glukozy krystalizują szybciej np. rzepakowy. Miody posiadające przewagę fruktozy pozostają znacznie dłużej w stanie płynnym np. akacjowy.
      Miód z uwagi na fakt że w jego skład wchodzi mnóstwo naturalnych składników mineralnych, jest nie tyle pokarmem, który uzupełnia w naszym organiźmie braki. Dodatkowym atutem jest to, że miód pszczeli wpływa bardzo korzystnie na wygląd zewnętrzny, czyli na cerę. Przysmak pszczeli krystalizując zmienia jedynie kolor, nie zmieniają się jego wartości odżywczo-lecznicze. Skrystalizowany nazywamy (krupcem), natomiast płynny (patoką). Skrystalizowany możemy doprowadzić ponownie do stanu płynnego nie zmieniając jego cennych właściwości poprzez podgrzanie do temperatury nie przekraczającej 42 stopnie C.
      Zgodnie z Polska normą wyróżniamy miody:
      <ul>
        <li>miody nektarowe</li>
        <li>nektarowo-spadziowe</li>
        <li>spadziowe</li>
      </ul>
      <p>Miody nektarowe oznacza się literą „N” miody nektarowo-spadziowe oznacza się literami NS miody spadziowe oznacza się literą ‘S’</p>
      <p>Jak spożywać <strong>Miód pszczeli</strong>? Najlepiej rano na czczo i wieczorem, rozpuszczając kilka godzin wcześniej w małej ilości wody. Dawkowanie dzienne: dzieci do lat 16 od 10do 40g. osoby dorosłe od 40 do 60 g. podane ilości można spożywać jednorazowo lub dzielić.</p>
    </div>
  </Layout>
)

export default Honey
